import {
  Popover,
  Box,
  Typography,
  Avatar,
  IconButton,
  TextField,
  // Select,
  // MenuItem,
  Button,
  CircularProgress,
} from "@mui/material";
import { useContext, useState } from "preact/hooks";
import { TranslateContext } from "@denysvuika/preact-translate";
import CloseIcon from "@mui/icons-material/Close";
import { NotificationContext } from "../context/NotificationContext";
import { addWorkflowLevelOwner } from "../services/workflowService";

const AddOwnersPopover = ({
  anchorEl,
  onClose,
  // onAddOwners,
  workflowId,
  levelId,
  onLevelUpdate,
}) => {
  const { t } = useContext(TranslateContext);

  const { showNotification } = useContext(NotificationContext);
  const [searchQuery, setSearchQuery] = useState("");
  // const [ownerType, setOwnerType] = useState("All");
  const [loading, setLoading] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState(null);

  // Mock data - replace with your actual data source
  const mockOwners = [
    { id: "20", name: "Admin", type: "Admin" },
    { id: "21", name: "Center Admin", type: "Admin" },
    { id: "22", name: "Vision officer", type: "Admin" },
  ];

  const filteredOwners = mockOwners.filter((owner) =>
    owner.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleOwnerSelect = (owner) => {
    setSelectedOwner(owner);
  };

  const handleAddOwners = async () => {
    if (!selectedOwner) {
      showNotification({
        message: t("shared.pleaseSelectOwner"),
        type: "error",
      });
      return;
    }

    setLoading(true);
    try {
      const response = await addWorkflowLevelOwner(
        selectedOwner.id,
        workflowId,
        levelId
      );

      if (response.success) {
        onLevelUpdate?.();
        showNotification({
          message: t("workflow.ownerAddedSuccess"),
          type: "success",
        });
        onClose();
      } else {
        showNotification({
          message: response.message || t("shared.errorOccurred"),
          type: "error",
        });
      }
    } catch (error) {
      showNotification({ message: t("shared.errorOccurred"), type: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Box sx={{ width: 300 }}>
        <Box
          sx={{
            p: 2,
            borderBottom: "1px solid #e0e0e0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">{t("workflow.addOwners")}</Typography>
          <IconButton size="small" onClick={onClose} sx={{ color: "#afbacf" }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        <Box sx={{ p: 2 }}>
          {/* <Select
            fullWidth
            value={ownerType}
            // @ts-ignore
            onChange={(e) => setOwnerType(e.target.value)}
            size="small"
            sx={{ mb: 2 }}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Corporate">Corporate</MenuItem>
            <MenuItem value="Admin">System Admin</MenuItem>
          </Select> */}

          <TextField
            fullWidth
            size="small"
            placeholder={t("shared.search")}
            value={searchQuery}
            // @ts-ignore
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ mb: 2 }}
          />

          <Box
            sx={{
              maxHeight: "300px",
              overflowY: "auto",
              "&::-webkit-scrollbar": { display: "none" },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            {filteredOwners.length === 0 ? (
              <Box sx={{ p: 2, textAlign: "center" }}>
                <Typography>{t("shared.noResults")}</Typography>
              </Box>
            ) : (
              filteredOwners.map((owner) => (
                <Box
                  key={owner.id}
                  onClick={() => handleOwnerSelect(owner)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    py: 1,
                    px: 2,
                    cursor: "pointer",
                    backgroundColor:
                      selectedOwner?.id === owner.id
                        ? "#e3f2fd"
                        : "transparent",
                    "&:hover": {
                      backgroundColor:
                        selectedOwner?.id === owner.id ? "#e3f2fd" : "#f8f9fa",
                    },
                  }}
                >
                  <Avatar
                    sx={{
                      width: 24,
                      height: 24,
                      mr: 1,
                      bgcolor:
                        owner.type === "Corporate" ? "#ef5350" : "#2196f3",
                    }}
                  >
                    {owner.name.charAt(0).toUpperCase()}
                  </Avatar>
                  <Box>
                    <Typography>{owner.name}</Typography>
                    <Typography variant="caption" color="text.secondary">
                      {owner.type}
                    </Typography>
                  </Box>
                </Box>
              ))
            )}
          </Box>

          <Button
            fullWidth
            variant="contained"
            onClick={handleAddOwners}
            disabled={loading || !selectedOwner}
            sx={{ mt: 2 }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t("shared.add")
            )}
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};

export default AddOwnersPopover;
