import {
  Box,
  Button,
  Container,
  Divider,
  InputAdornment,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import Workflow from "../components/Workflow";
import TablePagination from "../components/TablePagination";
import SearchIcon from "@mui/icons-material/Search";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "preact/hooks";
import { getWorkflowList } from "../services/workflowService";
import WorkflowEditor from "../components/WorkflowEditor";
import { TranslateContext } from "@denysvuika/preact-translate";
import { ENAR } from "../utils/maskENAR";
import { openAssignedUserModal } from "../store/assignedUserModalSignal";
import AssignedUserModal from "../components/AssignedUser";
import { openWorkflowActionModal } from "../store/workflowActionModal";
import WorkflowActionModal from "../components/WorkflowActions";


const rowsPerPageOptions = [5, 10, 15, 20];

// Custom debounce function
const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const useWorkflows = (searchTerm) => {
  const [workflows, setWorkflows] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchWorkflows = useCallback(
    async (showLoader = true) => {
      if (showLoader) setLoading(true);
      try {
        const params = searchTerm ? { title_primary: searchTerm } : {};
        const response = await getWorkflowList(params);
        setWorkflows(response.success ? response.data : []);
      } catch (error) {
        console.error("Failed to fetch workflows", error);
        setWorkflows([]);
      } finally {
        if (showLoader) setLoading(false);
      }
    },
    [searchTerm]
  );

  useEffect(() => {
    fetchWorkflows();
  }, [fetchWorkflows]);

  return { workflows, loading, fetchWorkflows };
};

export function WorkflowList() {
  const { t } = useContext(TranslateContext);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const { workflows, loading, fetchWorkflows } = useWorkflows(searchTerm);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedWorkflow, setSelectedWorkflow] = useState({});

  const handleChangePage = useCallback((_, newPage) => setPage(newPage), []);
  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  }, []);

  const openEditWorkflow = (event, workflow) => {
    setAnchorEl(event.currentTarget);
    setSelectedWorkflow(workflow);
  };

  const closeCreateWorkflow = useCallback(() => {
    setAnchorEl(null);
    setSelectedWorkflow({});
  }, []);

  const handleRefreshWorkflows = useCallback(() => {
    fetchWorkflows(false);
  }, [fetchWorkflows]);

  // Debounced search handler to prevent excessive API calls
  const debouncedSearch = useMemo(
    () =>
      debounce((term) => {
        setSearchTerm(term);
      }, 300),
    []
  );

  const handleSearchChange = useCallback(
    (event) => {
      const value = event.target.value;
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  const handleWorkflowEdit = useCallback((event, workflow) => {
    openEditWorkflow(event, workflow);
  }, []);

  const memoizedWorkflows = useMemo(
    () =>
      workflows.map((workflow, index) => (
        <Workflow
          key={`${workflow.id}${workflow.user_id}`}
          // @ts-ignore
          id={workflow.id}
          title={ENAR([workflow.title_primary, workflow.title_secondary])}
          sx={{ mb: index !== workflows.length - 1 ? 2 : 0 }}
          openEditWorkflow={(event) => handleWorkflowEdit(event, workflow)}
          onDelete={handleRefreshWorkflows}
        />
      )),
    [workflows, handleWorkflowEdit, handleRefreshWorkflows]
  );

  const styles = {
    container: {
      px: 10,
      py: 2,
      backgroundColor: "#F8F8F8",
      height: "100%",
      overflow: "auto",
      maxHeight: "100vh",
      minHeight: "100vh",
      "&::-webkit-scrollbar": { display: "none" },
      msOverflowStyle: "none",
      scrollbarWidth: "none",
    },
    workflowBox: {
      backgroundColor: "#ffff",
      p: 3,
      borderRadius: 2,
      height: "calc(100% - 80px)",
      overflow: "auto",
    },
    searchBox: {
      my: 2,
    },
    searchField: {
      width: "25%",
    },
  };

  return (
    <Container maxWidth="xl" disableGutters sx={styles.container}>
      <Typography
        variant="h5"
        sx={{
          py: "20px",
          fontWeight: 700,
          lineHeight: "28px",
          fontSize: "24px",
        }}
      >
        {t("shared.workflow")}
      </Typography>

      <Box sx={styles.workflowBox}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={styles.searchBox}
        >
          <TextField
            type="text"
            placeholder={t("shared.search")}
            sx={styles.searchField}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <Button
            variant="contained"
            color="primary"
            sx={{ color: "white", textTransform: "none" }}
            onClick={(event) => openEditWorkflow(event, {})}
          >
            {t("workflow.createWorkflow")}
          </Button>
        </Box>

        {loading && workflows.length === 0 ? (
          <Box display="flex" justifyContent="center" my={5}>
            <CircularProgress />
          </Box>
        ) : (
          memoizedWorkflows
        )}

        {!loading && workflows.length > 0 && (
          <>
            <Divider sx={{ my: 4 }} />

            <TablePagination
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              totalRows={workflows.length}
              page={page}
              handleChangePage={handleChangePage}
            />
          </>
        )}
      </Box>

      {anchorEl && (
        <WorkflowEditor
          anchorEl={anchorEl}
          close={closeCreateWorkflow}
          onSuccess={handleRefreshWorkflows}
          workflow={selectedWorkflow}
        />
      )}

      {openAssignedUserModal && <AssignedUserModal />}

      {openWorkflowActionModal && <WorkflowActionModal />}

  
    </Container>
  );
}

export default WorkflowList;
