import config from "../config";

export const ENAR = ([primary, secondary]) => {
  const currentlanguage = config.lang;
  const secondaryLanguage = config.language.secondary;
  
  if (
    currentlanguage === secondaryLanguage &&
    secondary.length &&
    secondary !== 'null'
  ) {
    return secondary;
  }

  return primary;
};
