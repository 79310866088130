import { computed, signal } from "@preact/signals";

// Signal to manage the token state
export const tenantSignal = signal(null);

/**
 * Updates the token signal and stores it in localStorage.
 *
 * @param token - The new token to store.
 */
export const setTenant = (tenant) => {
  tenantSignal.value = tenant;
};

/**
 * Clears the token signal and removes it from localStorage.
 */
export const clearTenant = () => {
  tenantSignal.value = null;
};

export const getTenantToken = computed(() => {
  return tenantSignal.value.token && tenantSignal.value.token !== null
    ? tenantSignal.value.token
    : null;
});
