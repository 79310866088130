import { h } from "preact";
import { useDirection } from "./context/DirectionContext";
import WorkflowListPage from "./pages/WorkflowList";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useEffect, useState, useMemo } from "preact/hooks";
import { fetchTenantToken, getUserToken } from "./services/authService";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import { CircularProgress } from "@mui/material";
import { NotificationProvider } from "./context/NotificationContext";

const cacheRtl = createCache({
  key: "mui-rtl",
  stylisPlugins: [rtlPlugin],
});

const cacheLtr = createCache({
  key: "mui-ltr",
});

function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let mounted = true;

    async function fetchToken() {
      try {
        const response = await fetchTenantToken();
        if (!mounted) return;

        if (response.success) {
          const tokenRes = await getUserToken();
          if (!mounted) return;

          if (tokenRes.success) {
            setIsAuthenticated(true);
          }
        }
      } catch (error) {
        if (mounted) {
          console.error("Error fetching token:", error);
          setError(error);
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    }

    fetchToken();
    return () => {
      mounted = false;
    };
  }, []);

  return { isAuthenticated, loading, error };
}

function App() {
  const { isAuthenticated, loading, error } = useAuth();
  const { isRTL } = useDirection();

  const theme = useMemo(
    () =>
      createTheme({
        direction: isRTL ? "rtl" : "ltr",
        palette: {
          primary: {
            main: "#00a7b7",
          },
        },
        typography: {
          fontFamily: "'Tajawal', sans-serif",
        },
      }),
    [isRTL]
  );

  const cache = isRTL ? cacheRtl : cacheLtr;

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {isAuthenticated ? (
          <NotificationProvider>
            <WorkflowListPage />
          </NotificationProvider>
        ) : (
          <p>Authentication failed {error}</p>
        )}
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
