// TablePagination.js
import { TranslateContext } from "@denysvuika/preact-translate";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Pagination,
  PaginationItem,
} from "@mui/material";
import { useContext } from "preact/hooks";
import { maskArabicNumbers } from "../utils/maskArabicNumber";

const TablePagination = ({
  rowsPerPage,
  rowsPerPageOptions,
  handleChangeRowsPerPage,
  totalRows,
  page,
  handleChangePage,
}) => {
  const { t } = useContext(TranslateContext);

  const startRow = (page - 1) * rowsPerPage + 1;
  const endRow = Math.min(page * rowsPerPage, totalRows);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" alignItems="center" gap={1}>
        <Typography>{t("shared.rowsPerPage")} :</Typography>
        <Select
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
          size="small"
          sx={{ minWidth: 60 }}
        >
          {rowsPerPageOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {maskArabicNumbers(option)}
            </MenuItem>
          ))}
        </Select>
        <Typography>
          {t("shared.startEndRow", {
            start: maskArabicNumbers(startRow),
            end: maskArabicNumbers(endRow),
            total: maskArabicNumbers(totalRows),
          })}
        </Typography>
      </Box>

      <Pagination
        count={Math.ceil(totalRows / rowsPerPage)}
        page={page}
        onChange={handleChangePage}
        variant="outlined"
        shape="rounded"
        color="primary"
        renderItem={(item) => (
          <PaginationItem {...item} page={maskArabicNumbers(item.page)} />
        )}
      />
    </Box>
  );
};

export default TablePagination;
