import { computed, signal } from "@preact/signals";

// const storedToken = localStorage.getItem("authToken");

// Signal to manage the token state
export const tokenSignal = signal(null);

/**
 * Updates the token signal and stores it in localStorage.
 *
 * @param token - The new token to store.
 */
export const setToken = (token) => {
  tokenSignal.value = token;
  // localStorage.setItem("authToken", token);
};

/**
 * Clears the token signal and removes it from localStorage.
 */
export const clearToken = () => {
  tokenSignal.value = null;
  // localStorage.removeItem("authToken");
};

export const getToken = computed(() => {
  return tokenSignal.value.token && tokenSignal.value !== null
    ? tokenSignal.value.token
    : null;
});
