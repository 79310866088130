import { apiClient } from "../utils/apiClient";
import { getTenantToken, setTenant } from "../store/tenantSignal";
import { setToken } from "../store/tokenSignal";
import config from "../config";

/**
 * Fetches a new token from the authentication API.
 * This function simulates a POST request to fetch the token.
 * @param tenant_key : required tenant key
 * @param tenant_secret : required tenant secret
 * @returns The token as a string, or null if the request fails.
 */
export const fetchTenantToken = async () => {
  const payload = new URLSearchParams({
    tenant_key: config.tenant.key,
    tenant_secret: config.tenant.secret,
  });

  const response = await apiClient(
    "api/auth/tenant/token",
    {
      method: "GET",
      param: payload,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
    false
  );

  if (response.success) {
    setTenant(response);
  }

  return response;
};

export const getUserToken = async () => {
  const payload = config.user;

  const response = await apiClient(
    "api/auth/user/token",
    {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Authorization: `Bearer ${getTenantToken.value}`,
        "Content-Type": "application/json",
      },
    },
    false
  );

  if (response.success) {
    setToken(response);
  }

  return response;
};
