import {
  Typography,
  Box,
  IconButton,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import Level from "./Level";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useContext,
  useRef,
} from "preact/hooks";
import GroupsIcon from "@mui/icons-material/Groups";
import {
  assignedUsersObject,
  openAssignedUserModal,
} from "../store/assignedUserModalSignal";
import {
  getWorkflowLevelList,
  deleteWorkflow,
  orderLevels,
} from "../services/workflowService";
import { memo } from "preact/compat";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import LevelEditor from "./LevelEditor";
import { NotificationContext } from "../context/NotificationContext";
import { TranslateContext } from "@denysvuika/preact-translate";

const rowsPerBox = 3;

const formatLevelsData = (data) => {
  return Object.values(
    data.reduce((acc, item) => {
      const levelOrder = item.level_order;
      if (!acc[levelOrder]) acc[levelOrder] = [];
      acc[levelOrder].push(item);
      return acc;
    }, {})
  );
};

const Workflow = ({ id, title, sx, openEditWorkflow, onDelete }) => {
  const { showNotification } = useContext(NotificationContext);
  const { t } = useContext(TranslateContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [levelsList, setLevelsList] = useState([]);
  const levelsListRef = useRef(levelsList);
  const [loading, setLoading] = useState(true);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const openLevelEditor = (event, level) => {
    setAnchorEl(event.currentTarget);
    setSelectedLevel(level);
  };

  const closeLevelEditor = () => {
    setAnchorEl(null);
    setSelectedLevel(null);
  };

  const getWorkflowLevel = useCallback(
    async (showLoader = true) => {
      if (showLoader) setLoading(true);
      try {
        const response = await getWorkflowLevelList(id);
        setLevelsList(response.success ? response.data : []);
      } catch (error) {
        console.error("Error fetching workflow levels:", error);
      } finally {
        if (showLoader) setLoading(false);
      }
    },
    [id]
  );

  useEffect(() => {
    getWorkflowLevel();
  }, [getWorkflowLevel]);

  useEffect(() => {
    levelsListRef.current = levelsList;
  }, [levelsList]);

  const handleDeleteClick = () => setOpenDeleteDialog(true);
  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const handleConfirmDelete = async () => {
    try {
      const response = await deleteWorkflow(id);
      if (response.success) {
        showNotification({
          message: t("workflow.deleteSuccess"),
          type: "success",
        });
        handleCloseDeleteDialog();
        if (onDelete) onDelete();
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      showNotification({ message: t("workflow.deleteError"), type: "error" });
      console.error("Error deleting workflow:", error);
    }
  };

  const handleChangeOrder = useCallback(
    async (level, change) => {
      try {
        const flatLevels = levelsListRef.current.flat();

        if (flatLevels.length === 0) {
          return;
        }

        const newOrder = Number(level.level_order) + change;

        if (newOrder < 1 || newOrder > flatLevels.length) {
          return;
        }

        const updatedLevels = flatLevels.map((l) =>
          l.id === level.id ? { ...l, level_order: newOrder } : l
        );

        const uniqueOrders = [
          ...new Set(updatedLevels.map((l) => l.level_order)),
        ].sort((a, b) => a - b);

        updatedLevels.forEach((l) => {
          l.level_order = uniqueOrders.indexOf(l.level_order) + 1;
        });

        setLevelsList(formatLevelsData(updatedLevels));

        const payload = updatedLevels.reduce((acc, l) => {
          acc[l.id] = l.level_order;
          return acc;
        }, {});

        const response = await orderLevels(payload);
        if (response.success) {
          showNotification({
            message: t("shared.updatedSuccessfully"),
            type: "success",
          });
        }
      } catch (error) {
        showNotification({ message: t("shared.somethingBad"), type: "error" });
      }
    },
    [showNotification, t]
  );

  const memoizedLevels = useMemo(
    () =>
      levelsList.map((levels, index) => (
        <Box
          key={index}
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            backgroundColor: "#F2F5F8",
            minHeight: "235px",
            maxHeight: "376px",
            width: "274px",
            borderRadius: 2,
            flex: "0 0 auto",
            p: 1,
            overflowY: "auto",
            "&::-webkit-scrollbar": { display: "none" },
            justifyContent:
              levels.length < rowsPerBox ? "center" : "flex-start",
          }}
        >
          {levels.map((level) => (
            <Level
              key={level.id}
              // @ts-ignore
              level={level}
              editLevel={(event) => openLevelEditor(event, level)}
              onSuccess={getWorkflowLevel}
              onChangeOrder={handleChangeOrder}
            />
          ))}
        </Box>
      )),
    [levelsList, getWorkflowLevel, handleChangeOrder]
  );

  // @ts-ignore
  const RenderActionButton = memo(({ title, IconComponent, onClick }) => (
    <Tooltip title={title}>
      <IconButton size="small" sx={{ color: "#8c8c8c" }} onClick={onClick}>
        <IconComponent sx={{ color: "#afbacf" }} fontSize="small" />
      </IconButton>
    </Tooltip>
  ));

  return (
    <>
      <Box
        sx={{
          ...sx,
          border: "1px solid #ececec",
          borderRadius: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#f8f8f8",
            p: 2,
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 500, fontSize: "18px" }}>
            {title}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <RenderActionButton
              // @ts-ignore
              title={t("workflow.assignedUsers")}
              IconComponent={GroupsIcon}
              onClick={() => {
                openAssignedUserModal.value = true;
                assignedUsersObject.value = { workflow_id: id };
              }}
            />

            <Tooltip title={t("workflow.addLevel")}>
              <IconButton
                size="small"
                sx={{ color: "#8c8c8c" }}
                onClick={(event) => openLevelEditor(event, {})}
              >
                <LibraryAddIcon sx={{ color: "#afbacf" }} fontSize="small" />
              </IconButton>
            </Tooltip>

            <RenderActionButton
              // @ts-ignore
              title={t("shared.edit")}
              IconComponent={BorderColorIcon}
              onClick={openEditWorkflow}
            />

            <RenderActionButton
              // @ts-ignore
              title={t("shared.delete")}
              IconComponent={DeleteIcon}
              onClick={handleDeleteClick}
            />
          </Box>
        </Box>

        <Box
          display="flex"
          gap={2}
          sx={{
            backgroundColor: "#ffff",
            p: 2,
            overflowX: "auto",
            "&::-webkit-scrollbar": { display: "none" },
            minHeight: "300px",
          }}
        >
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <CircularProgress />
            </Box>
          ) : levelsList.length > 0 ? (
            memoizedLevels
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="200px"
            >
              <Typography variant="body1" color="error">
                {t("workflow.noLevelsAvailable")}
              </Typography>
            </Box>
          )}
        </Box>

        <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
          <DialogTitle>{t("workflow.deleteConfirmTitle")}</DialogTitle>
          <DialogContent>
            <Typography>
              {t("workflow.deleteConfirmMessage", { title })}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              {t("shared.cancel")}
            </Button>
            <Button
              onClick={handleConfirmDelete}
              color="error"
              variant="contained"
              autoFocus
            >
              {t("shared.delete")}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      {anchorEl && (
        <LevelEditor
          anchorEl={anchorEl}
          close={closeLevelEditor}
          level={selectedLevel}
          onSuccess={getWorkflowLevel}
          workflowId={id}
          step={levelsList.length + 1}
        />
      )}
    </>
  );
};

export default memo(Workflow);
