import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { assignedUsersObject, openAssignedUserModal } from "../store/assignedUserModalSignal";
import { lazy, Suspense, useContext } from "preact/compat";
import { TranslateContext } from "@denysvuika/preact-translate";

const UserTable = lazy(() => import("./Users"));

function AssignedUserModal() {
  const { t } = useContext(TranslateContext);
  return (
    <Dialog
      open={openAssignedUserModal.value === true}
      onClose={() => {
        openAssignedUserModal.value = false;
        assignedUsersObject.value = {};
      }}
      fullWidth
      maxWidth="md"
      disableEnforceFocus
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        sx={{ backgroundColor: "#F9FAFA" }}
      >
        <Typography variant="h6">{t("workflow.assignedUsers")}</Typography>
        <IconButton
          onClick={() => {
            openAssignedUserModal.value = false;
            assignedUsersObject.value = {};
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          backgroundColor: "#F2F5F8",
        }}
      >
        <Suspense
          fallback={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress />
            </Box>
          }
        >
          <UserTable />
        </Suspense>
      </DialogContent>
    </Dialog>
  );
}

export default AssignedUserModal;
