import { createContext } from "preact";
import { useState, useContext, useEffect, useCallback } from "preact/hooks";
import { TranslateContext } from "@denysvuika/preact-translate";

const DirectionContext = createContext({
  language: "en",
  isRTL: false,
  // eslint-disable-next-line no-unused-vars
  changeLanguage: (_lang) => {},
});

const rtlLanguages = ["ar", "ur", "fr"];

export const DirectionProvider = ({ children, defaultLanguage = "en" }) => {
  const { setLang, lang } = useContext(TranslateContext);
  const [rtl, setRTL] = useState(false);

  const isRtl = (lang) => rtlLanguages.includes(lang);

  const changeLanguage = useCallback(
    (lang) => {
      // @ts-ignore
      setLang(lang);
      setRTL(isRtl(lang));
      document.documentElement.setAttribute("dir", isRtl(lang) ? "rtl" : "ltr");
    },
    [setLang]
  );

  useEffect(() => {
    changeLanguage(defaultLanguage);
  }, [changeLanguage, defaultLanguage]);

  return (
    <DirectionContext.Provider
      value={{ language: lang, isRTL: rtl, changeLanguage }}
    >
      {children}
    </DirectionContext.Provider>
  );
};

export const useDirection = () => useContext(DirectionContext);
