import config from "../config";
import { apiClient } from "../utils/apiClient";

export const createWorkflow = async (workflowData) => {
  const formData = {
    title_primary: workflowData.title,
    title_secondary: workflowData.titleSecondary,
    role_id: workflowData.role,
    reference_type: workflowData.type,
    user_id: config.user.tenant_user_id.toString(),
    assign_to_new_users: workflowData.assignToNewUsers,
    ...(workflowData.assignOnFormSubmit && {
      submitted_reference: workflowData.submittedReference,
      submitted_id: workflowData.submittedId,
    }),
   
  };

  const response = await apiClient(
    "workflow/create",
    {
      method: "POST",
      param: new URLSearchParams({ tenant_id: config.user.tenant_id.toString() }),
      body: new URLSearchParams(formData),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
    true
  );

  return response;
};

export const getWorkflowList = async (params) => {
  const queryParam = params ? new URLSearchParams(params) : {};
  const response = await apiClient(
    "workflow/list",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      param: queryParam,
    },
    true
  );

  response.data = response.data.reverse();
  return response;
};

export const getWorkflowLevelList = async (workflow_id, user_id) => {
  const params = { workflow_id };
  if (user_id) {
    params.user_id = user_id;
  }

  const response = await apiClient(
    "workflow-level/list",
    {
      method: "GET",
      param: new URLSearchParams(params),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
    true
  );

  
  if (response.success) {
   
    const formattedData = [];
    const levelMap = {};

    response.data.forEach((item) => {
      const levelOrder = item.level_order;

      if (!levelMap[levelOrder]) {
        levelMap[levelOrder] = [];
      }

      levelMap[levelOrder].push(item);
    });

    for (const level in levelMap) {
      if (Object.prototype.hasOwnProperty.call(levelMap, level)) {
        formattedData.push(levelMap[level]);
      }
    }

    response.data = formattedData;
  }

  return response;
};

export const updateWorkflow = async (workflowId, workflowData) => {
  const formData = {
    id: workflowId,
    title_primary: workflowData.title,
    title_secondary: workflowData.titleSecondary,
    user_id: config.user.tenant_user_id.toString(),
    role_id: workflowData.role,
    reference_type: workflowData.type,
    assign_to_new_users: workflowData.assignToNewUsers,
    submitted_id: workflowData.submittedId || "0",
    submitted_reference: workflowData.submittedReference || "",
  };

  const response = await apiClient(
    "workflow/update",
    {
      method: "PUT",
      param: new URLSearchParams({ tenant_id: config.user.tenant_id.toString() }),
      body: new URLSearchParams(formData),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
    true
  );

  return response;
};

export const deleteWorkflow = async (workflowId) => {
  const formData = {
    id: workflowId
  };

  const response = await apiClient(
    "workflow/delete",
    {
      method: "POST",
      param: new URLSearchParams({ tenant_id: config.user.tenant_id.toString() }),
      body: new URLSearchParams(formData),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
    true
  );

  return response;
};

export const createWorkflowLevel = async (levelData) => {
  const formData = {
    title_primary: levelData.title_primary,
    title_secondary: levelData.title_secondary,
    workflow_id: levelData.workflow_id,
    level_order: levelData.level_order,
    bypassable: levelData.bypassable ? "1" : "0",
    downgradable: levelData.downgradable ? "1" : "0",
    approved_template_email: levelData.approved_template_email || "",
    rejected_template_email: levelData.rejected_template_email || "",
  };

  const response = await apiClient(
    "workflow-level/create",
    {
      method: "POST",
      param: new URLSearchParams({ tenant_id: config.user.tenant_id.toString() }),
      body: new URLSearchParams(formData),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
    true
  );

  return response;
};

export const updateWorkflowLevel = async (levelData) => {
  const formData = {
    id: levelData.id.toString(),
    workflow_id: levelData.workflow_id.toString(),
    title_primary: levelData.title_primary,
    title_secondary: levelData.title_secondary,
    level_order: levelData.level_order.toString(),
    bypassable: levelData.bypassable ? "1" : "0",
    downgradable: levelData.downgradable ? "1" : "0",
    approved_template_email: levelData.approved_template_email,
    rejected_template_email: levelData.rejected_template_email,
  };

  const response = await apiClient(
    "workflow-level/update",
    {
      method: "PUT",
      param: new URLSearchParams({ tenant_id: config.user.tenant_id.toString() }),
      body: new URLSearchParams(formData),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
    true
  );

  return response;
};

export const deleteWorkflowLevel = async (levelId) => {
  const formData = {
    id: levelId.toString()
  };

  const response = await apiClient(
    "workflow-level/delete",
    {
      method: "POST",
      param: new URLSearchParams({ tenant_id: config.user.tenant_id.toString() }),
      body: new URLSearchParams(formData),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
    true
  );

  return response;
};

export const orderLevels = async (orders) => {
  const formData = Object.entries(orders).reduce((acc, [id, order]) => {
    acc[`orders[${id}]`] = order;
    return acc;
  }, {});

  const response = await apiClient(
    "workflow-level/order",
    {
      method: "POST",
      param: new URLSearchParams({ tenant_id: config.user.tenant_id.toString() }),
      body: new URLSearchParams(formData),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
    true
  );

  return response;
};

export const getWorkflowLevelOwners = async (workflowId, workflowLevelId) => {
  const response = await apiClient(
    "workflow-level-owners/list",
    {
      method: "GET",
      param: new URLSearchParams({ 
        workflow_id: workflowId,
        workflow_level_id: workflowLevelId 
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
    true
  );

  return response;
};

export const addWorkflowLevelOwner = async (ownerId, workflowId, workflowLevelId) => {
  const formData = {
    user_id: ownerId.toString(),
    workflow_id: workflowId.toString(),
    workflow_level_id: workflowLevelId.toString()
  };

  const response = await apiClient(
    "workflow-level-owners/create",
    {
      method: "POST",
      param: new URLSearchParams({ tenant_id: config.user.tenant_id.toString() }),
      body: new URLSearchParams(formData),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
    true
  );

  return response;
};

export const deleteWorkflowLevelOwner = async (ownerId) => {
  const formData = {
    id: ownerId.toString()
  };

  const response = await apiClient(
    "workflow-level-owners/delete",
    {
      method: "POST",
      param: new URLSearchParams({ tenant_id: config.user.tenant_id.toString() }),
      body: new URLSearchParams(formData),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
    true
  );

  return response;
};

export const approveWorkflowLevel = async (userId, workflowLevelId) => {
  const formData = {
    user_id: userId.toString(),
    workflow_level_id: workflowLevelId.toString()
  };

  const response = await apiClient(
    "workflow-users/approve_workflow_user",
    {
      method: "POST",
      param: new URLSearchParams({ tenant_id: config.user.tenant_id.toString() }),
      body: new URLSearchParams(formData),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
    true
  );

  return response;
};

export const rejectWorkflowLevel = async (userId, workflowLevelId) => {
  const formData = {
    user_id: userId.toString(),
    workflow_level_id: workflowLevelId.toString()
  };

  const response = await apiClient(
    "workflow-users/reject_workflow_user",
    {
      method: "POST",
      param: new URLSearchParams({ tenant_id: config.user.tenant_id.toString() }),
      body: new URLSearchParams(formData),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
    true
  );

  return response;
};

export const getWorkflowLevelById = async (levelId) => {
  const response = await apiClient(
    "workflow-level/show",
    {
      method: "GET",
      param: new URLSearchParams({ 
        tenant_id: config.user.tenant_id.toString(),
        id: levelId.toString() 
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
    true
  );

  return response;
};
