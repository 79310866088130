import {
  Popover,
  Box,
  Typography,
  Avatar,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useContext, useEffect, useState } from "preact/hooks";
import { TranslateContext } from "@denysvuika/preact-translate";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import {
  getWorkflowLevelOwners,
  deleteWorkflowLevelOwner,
} from "../services/workflowService";
import { NotificationContext } from "../context/NotificationContext";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const OwnersPopover = ({
  anchorEl,
  onClose,
  workflowId,
  levelId,
  onLevelUpdate,
}) => {
  const { t } = useContext(TranslateContext);
  const { showNotification } = useContext(NotificationContext);
  const [owners, setOwners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [ownerToDelete, setOwnerToDelete] = useState(null);

  useEffect(() => {
    const fetchOwners = async () => {
      if (!anchorEl || hasError) return; // Don't fetch if there's an error or popover is closed

      setLoading(true);
      try {
        const response = await getWorkflowLevelOwners(workflowId, levelId);
        if (response.success) {
          setOwners(response.data || []);
          setHasError(false);
        } else {
          setOwners([]);
          setHasError(true);
        }
      } catch (error) {
        setHasError(true);
        setOwners([]);
        console.error("Error fetching owners:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOwners();
  }, [workflowId, levelId, anchorEl, t, hasError]);

  // Reset error state when popover closes
  useEffect(() => {
    if (!anchorEl) {
      setHasError(false);
    }
  }, [anchorEl]);

  const handleDeleteClick = (owner) => {
    console.log(owner);
    setOwnerToDelete(owner);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setOwnerToDelete(null);
  };

  const handleDeleteConfirm = async () => {
    if (!ownerToDelete) return;

    setLoading(true);
    try {
      // Optimistically remove the owner from the UI
      setOwners((prevOwners) =>
        prevOwners.filter((owner) => owner.id !== ownerToDelete.id)
      );

      const response = await deleteWorkflowLevelOwner(ownerToDelete.id);

      if (response.success) {
        showNotification({
          message: t("workflow.ownerDeletedSuccess"),
          type: "success",
        });

        // Get updated level information

        onLevelUpdate?.();

        // Refresh owners list
        const ownersResponse = await getWorkflowLevelOwners(
          workflowId,
          levelId
        );
        if (ownersResponse.success) {
          setOwners(ownersResponse.data || []);
        }
      } else {
        // If delete failed, revert the optimistic update
        const ownersResponse = await getWorkflowLevelOwners(
          workflowId,
          levelId
        );
        if (ownersResponse.success) {
          setOwners(ownersResponse.data || []);
        }
        showNotification({
          message: response.message || t("shared.errorOccurred"),
          type: "error",
        });
      }
    } catch (error) {
      // If there was an error, revert the optimistic update
      const ownersResponse = await getWorkflowLevelOwners(workflowId, levelId);
      if (ownersResponse.success) {
        setOwners(ownersResponse.data || []);
      }
      console.error("Error deleting owner:", error);
      showNotification({ message: t("shared.errorOccurred"), type: "error" });
    } finally {
      setLoading(false);
      setDeleteConfirmOpen(false);
      setOwnerToDelete(null);
    }
  };

  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ width: 300 }}>
          <Box
            sx={{
              p: 2,
              borderBottom: "1px solid #e0e0e0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">{t("workflow.owners")}</Typography>
            <IconButton
              size="small"
              onClick={onClose}
              sx={{ color: "#afbacf" }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box
            sx={{
              maxHeight: "300px",
              overflowY: "auto",
              "&::-webkit-scrollbar": { display: "none" },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            {loading ? (
              <Box
                sx={{
                  p: 2,
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: 100,
                }}
              >
                <CircularProgress size={24} />
              </Box>
            ) : owners.length === 0 ? (
              <Box sx={{ p: 2, textAlign: "center" }}>
                <Typography>{t("workflow.noOwners")}</Typography>
              </Box>
            ) : (
              owners.map((owner) => (
                <Box
                  key={owner.id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    py: 1,
                    px: 2,
                    "&:hover": {
                      backgroundColor: "#f8f9fa",
                    },
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Avatar
                      sx={{ width: 24, height: 24 }}
                      src={owner.avatar_url}
                    />
                    <Typography>{owner.name}</Typography>
                  </Box>
                  <IconButton
                    size="small"
                    onClick={() => handleDeleteClick(owner)}
                    sx={{
                      color: "#afbacf",
                      "&:hover": {
                        color: "error.main",
                      },
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              ))
            )}
          </Box>
        </Box>
      </Popover>

      {/* Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>{t("workflow.confirmDeleteOwner")}</DialogTitle>
        <DialogContent>
          <Typography>{t("workflow.deleteOwnerConfirmMessage")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            {t("shared.cancel")}
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t("shared.delete")
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OwnersPopover;
