// config.js

// Default configuration
const defaultConfig = {
  API_Path: "https://workflow-api-stg.classera.com/",
  lang: "ar",
  language: {
    primary: "en",
    secondary: "ar",
  },
  user: {
    name: "Admine",
    email: "Admin@classera.com",
    tenant_id: 1,
    sub_tenant_id: 0,
    tenant_user_id: 20,
    role_id: 1,
  },
  tenant: {
    key: "sLM7cqvSGGR4BAKRtyY5wv1ceNSqbvo8",
    secret: "35ffssJ0w4ic2JoJMq1XNSAC1E9",
  },
  roles: {
    ADMINISTRATOR: "1",
    SCHOOL_ADMIN: "2",
    GUARDIAN: "3",
    TEACHER: "4",
    OTHER_STAFF: "5",
    STUDENT: "6",
    ACCOUNTANT: "7",
    MANAGER: "8",
    ADVISER: "9",
    FINANCIAL_ADMIN: "10",
    TEACHER_SUPERVISOR: "11",
    FLOOR_SUPERVISOR: "12",
    KAGAN_COACH: "13",
    ADMISSION_OFFICER: "14",
    SUCCESS_PARTNER: "15",
    CORPORATE: "20",
  },
};

// Create a mutable config object
let config = {
  ...defaultConfig,
};

// Function to update config
export function updateConfig(newConfig) {
  config = {
    ...config,
    ...newConfig,
  };
  return config;
}

export default config;
