import {
  Checkbox,
  FormControlLabel,
  Popover,
  TextField,
  Box,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TranslateContext } from "@denysvuika/preact-translate";
import { useContext, useState } from "preact/hooks";
import config from "../config";
import { createWorkflowLevel, updateWorkflowLevel } from "../services/workflowService";
import { NotificationContext } from "../context/NotificationContext";

function LevelEditor({ anchorEl, close, level, onSuccess, workflowId,step }) {
  const { t } = useContext(TranslateContext);
  const { showNotification } = useContext(NotificationContext);
  const open = Boolean(anchorEl);
  const id = open ? "level-popover" : undefined;

  const [formData, setFormData] = useState({
    id: level?.id || "",
    title_primary: level?.title_primary || "",
    title_secondary: level?.title_secondary || "",
    approved_template_email: level?.approved_template_email || "",
    rejected_template_email: level?.rejected_template_email || "",
    bypassable: level?.bypassable || false,
    downgradable: level?.downgradable || false,
    level_order: level?.level_order || step,
    status: level?.status || 0,
    sub_tenant_id: level?.sub_tenant_id || 0,
    tenant_id: level?.tenant_id || 0,
    workflow_id: level?.workflow_id || workflowId,
  });

  const handleChange = (event) => {
    const { name, type, checked, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // const handleRichTextChange = (name) => (content) => {
  //   setFormData((prev) => ({
  //     ...prev,
  //     [name]: content,
  //   }));
  // };

  const textFields = [
    {
      name: "title_primary",
      label: t("shared.title"),
      required: true,
    },
    {
      name: "title_secondary",
      label: `${t("shared.title")} (${t(
        `language.${config.language.secondary}`
      )})`,
    },
    {
      name: "level_order",
      label: t("workflow.step"),
      type: "number",
      inputProps: { min: 0 }
    },
  ];

  const checkboxes = [
    {
      name: "bypassable",
      label: t("workflow.bypassable"),
    },
    {
      name: "downgradable",
      label: t("workflow.downgradable"),
    },
  ];

  const handleSubmit = async () => {
    try {
      const response = level?.id 
        ? await updateWorkflowLevel(formData)
        : await createWorkflowLevel(formData);

      if (response.success) {
        showNotification({
          message: t(level?.id ? "workflow.levelUpdated" : "workflow.levelCreated"),
          type: "success"
        });
        onSuccess?.();
        close();
      }
    } catch (error) {
      console.error("Error saving workflow level:", error);
      showNotification({
        message: t("shared.errorOccurred"),
        type: "error"
      });
    }
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={close}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      sx={{
        '& .MuiPopover-paper': {
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }
      }}
    >
      <Box
        sx={{
          p: 2,
          width: "400px",
          maxHeight: '80vh',
          // overflowY: 'auto',
          '& *': {
            scrollbarWidth: 'none !important',
            msOverflowStyle: 'none !important',
            '&::-webkit-scrollbar': {
              display: 'none !important'
            }
          },
          '& .ql-editor': {
            maxHeight: '150px',
            overflowY: 'auto'
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6">
            {level?.id ? t("workflow.updateLevel") : t("workflow.addLevel")}
          </Typography>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Text Fields */}
        {textFields.map((field) => (
          <TextField
            key={field.name}
            name={field.name}
            label={field.label}
            type={field.type || "text"}
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            value={formData[field.name]}
            onChange={handleChange}
            required={field.required}
            inputProps={field.inputProps}
          />
        ))}

        {/* Checkboxes */}
        {checkboxes.map((checkbox, index) => (
          <Box
            key={checkbox.name}
            sx={{
              display: "block",
              mb: index === checkboxes.length - 1 ? 2 : 1,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name={checkbox.name}
                  checked={formData[checkbox.name]}
                  onChange={handleChange}
                />
              }
              label={checkbox.label}
              labelPlacement="end"
            />
          </Box>
        ))}

        {/* Rich Text Editors */}
        {/* <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: "bold" }}>
          {t("workflow.emailOnApprove")}
        </Typography> */}
        <TextField
          name="approved_template_email"
          fullWidth
          multiline
          rows={4}
          value={formData.approved_template_email}
          onChange={handleChange}
          placeholder={t("workflow.typeApprovalEmail")}
          sx={{ mb: 2 }}
        />

        {/* <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 'bold' }}>
          {t("workflow.emailOnReject")}
        </Typography> */}
        <TextField
          name="rejected_template_email"
          fullWidth
          multiline
          rows={4}
          value={formData.rejected_template_email}
          onChange={handleChange}
          placeholder={t("workflow.typeRejectionEmail")}
          sx={{ mb: 2 }}
        />

        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ my: 2 }}
          disabled={!formData.title_primary.trim()}
          onClick={handleSubmit}
        >
          {level?.id ? t("shared.update") : t("shared.create")}
        </Button>
      </Box>
    </Popover>
  );
}

export default LevelEditor;
