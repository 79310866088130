import { memo } from "preact/compat";
import {
  Card,
  CardContent,
  Typography,
  AvatarGroup,
  Avatar,
  Box,
  IconButton,
  Divider,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import GroupsIcon from "@mui/icons-material/Groups";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useState, useContext, useCallback } from "preact/hooks";
import { useDirection } from "../context/DirectionContext";
import { ENAR } from "../utils/maskENAR";
import { NotificationContext } from "../context/NotificationContext";
import { TranslateContext } from "@denysvuika/preact-translate";
import {
  deleteWorkflowLevel,
  getWorkflowLevelById,
} from "../services/workflowService";
import OwnersPopover from "./OwnersPopover";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddOwnersPopover from "./AddOwnersPopover";
import {
  openAssignedUserModal,
  assignedUsersObject,
} from "../store/assignedUserModalSignal";

// Move styles outside component
const CARD_STYLES = {
  width: "100%",
  height: 120,
  textAlign: "center",
  backgroundColor: "#ffff",
  borderRadius: 2,
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  flex: "0 0 auto",
};

const AVATAR_GROUP_STYLES = {
  cursor: "pointer",
  alignItems: "center",
  justifyContent: "center",
  "& .MuiAvatar-root": {
    width: 24,
    height: 24,
    fontSize: 12,
  },
};

const ActionButtons = memo(
  ({
    // @ts-ignore
    handleMove,
    editLevel,
    handleDeleteClick,
    isRTL,
    handleAddOwnerClick,
    handleAssignedUsersClick,
    t,
  }) => {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Tooltip title={t("workflow.addOwners")}>
          <IconButton onClick={handleAddOwnerClick}>
            <PersonAddIcon sx={{ color: "#afbacf" }} fontSize="small" />
          </IconButton>
        </Tooltip>

        <Tooltip title={t("workflow.assignedUsers")}>
          <IconButton onClick={handleAssignedUsersClick}>
            <GroupsIcon sx={{ color: "#afbacf" }} fontSize="small" />
          </IconButton>
        </Tooltip>

        <Tooltip title={t("workflow.moveDown")}>
          <IconButton onClick={(e) => handleMove(e, -1)}>
            {isRTL ? (
              <KeyboardArrowRightIcon
                sx={{ color: "#afbacf" }}
                fontSize="small"
              />
            ) : (
              <KeyboardArrowLeftIcon
                sx={{ color: "#afbacf" }}
                fontSize="small"
              />
            )}
          </IconButton>
        </Tooltip>

        <Tooltip title={t("workflow.moveUp")}>
          <IconButton onClick={(e) => handleMove(e, 1)}>
            {isRTL ? (
              <KeyboardArrowLeftIcon
                sx={{ color: "#afbacf" }}
                fontSize="small"
              />
            ) : (
              <KeyboardArrowRightIcon
                sx={{ color: "#afbacf" }}
                fontSize="small"
              />
            )}
          </IconButton>
        </Tooltip>

        <Tooltip title={t("shared.edit")}>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              editLevel(event);
            }}
          >
            <BorderColorIcon sx={{ color: "#afbacf" }} fontSize="small" />
          </IconButton>
        </Tooltip>

        <Divider
          orientation="vertical"
          flexItem
          sx={{
            height: 25,
            borderWidth: 1.7,
            borderColor: "#E0E0E0",
            alignSelf: "center",
          }}
        />

        <Tooltip title={t("shared.delete")}>
          <IconButton onClick={handleDeleteClick}>
            <DeleteIcon sx={{ color: "#afbacf" }} fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    );
  }
);

// @ts-ignore
const Level = ({ level, editLevel, onSuccess, onChangeOrder }) => {
  const { isRTL } = useDirection();
  const { showNotification } = useContext(NotificationContext);
  const { t } = useContext(TranslateContext);

  const [dialogState, setDialogState] = useState({
    deleteOpen: false,
    ownersAnchorEl: null,
  });

  const [addOwnerAnchorEl, setAddOwnerAnchorEl] = useState(null);

  const handleMove = useCallback(
    (e, change) => {
      e.stopPropagation();
      onChangeOrder(level, change);
    },
    [level, onChangeOrder]
  );

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setDialogState({ ...dialogState, deleteOpen: true });
  };

  const handleCloseDeleteDialog = () => {
    setDialogState({ ...dialogState, deleteOpen: false });
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await deleteWorkflowLevel(level.id);
      if (response.success) {
        showNotification({
          message: t("workflow.levelDeleted"),
          type: "success",
        });
        handleCloseDeleteDialog();
        onSuccess?.();
      }
    } catch (error) {
      showNotification({
        message: t("workflow.deleteError"),
        type: "error",
      });
    }
  };

  const handleOwnersClick = (event) => {
    event.stopPropagation();
    setDialogState({ ...dialogState, ownersAnchorEl: event.currentTarget });
  };

  const handleOwnersClose = () => {
    setDialogState({ ...dialogState, ownersAnchorEl: null });
  };

  // const handleRemoveOwner = useCallback((ownerId) => {
  //   console.log(ownerId);
  // }, []);

  const handleAddOwnerClick = (event) => {
    event.stopPropagation();
    setAddOwnerAnchorEl(event.currentTarget);
  };

  const handleAddOwnerClose = () => {
    setAddOwnerAnchorEl(null);
  };

  // const handleAddOwners = () => {
  //   // console.log("Selected owners:", selectedOwners);
  //   handleAddOwnerClose();
  // };

  const handleAssignedUsersClick = (event) => {
    event.stopPropagation();
    openAssignedUserModal.value = true;
    assignedUsersObject.value = {
      workflow_id: level.workflow_id,
      level_id: level.id,
    };
  };

  const handleLevelUpdate = useCallback(async () => {
    try {
      const levelResponse = await getWorkflowLevelById(level.id);
      if (levelResponse.success) {
        Object.assign(level, levelResponse.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [level]);

  return (
    <>
      <Card sx={CARD_STYLES}>
        <CardContent sx={{ p: 2 }}>
          <Typography variant="body2" sx={{ fontWeight: 600, mb: 1 }}>
            {ENAR([level.title_primary, level.title_secondary])}
          </Typography>

          {/* {!isEditing ? ( */}

          {level.owners > 0 && (
            <Tooltip title="Owners">
              <Button
                onClick={handleOwnersClick}
                sx={{
                  textTransform: "none",
                }}
              >
                <AvatarGroup max={3} sx={AVATAR_GROUP_STYLES}>
                  {Array(Math.min(2, level.owners || 0))
                    .fill(null)
                    .map((_, index) => (
                      <Avatar key={`owner-${index}`} />
                    ))}
                  {level.owners > 2 && (
                    <Typography
                      variant="caption"
                      color="primary"
                      sx={{ fontWeight: 500 }}
                    >
                      {`+${level.owners - 2}`}
                    </Typography>
                  )}
                </AvatarGroup>
              </Button>
            </Tooltip>
          )}
          <ActionButtons
            // @ts-ignore
            handleMove={handleMove}
            editLevel={editLevel}
            handleDeleteClick={handleDeleteClick}
            isRTL={isRTL}
            handleAddOwnerClick={handleAddOwnerClick}
            handleAssignedUsersClick={handleAssignedUsersClick}
            t={t}
          />
        </CardContent>
      </Card>

      <Dialog
        open={dialogState.deleteOpen}
        onClose={handleCloseDeleteDialog}
        onClick={(e) => e.stopPropagation()}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">
          {t("workflow.deleteLevelConfirmTitle")}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {t("workflow.deleteLevelConfirmMessage", {
              title: ENAR([level.title_primary, level.title_secondary]),
            })}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            {t("shared.cancel")}
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="error"
            variant="contained"
            autoFocus
          >
            {t("shared.delete")}
          </Button>
        </DialogActions>
      </Dialog>

      {dialogState.ownersAnchorEl && (
        <OwnersPopover
          anchorEl={dialogState.ownersAnchorEl}
          onClose={handleOwnersClose}
          workflowId={level.workflow_id}
          levelId={level.id}
          onLevelUpdate={handleLevelUpdate}
        />
      )}

      {addOwnerAnchorEl && (
        <AddOwnersPopover
          anchorEl={addOwnerAnchorEl}
          onClose={handleAddOwnerClose}
          workflowId={level.workflow_id}
          levelId={level.id}
          onLevelUpdate={handleLevelUpdate}
        />
      )}
    </>
  );
};

export default memo(Level);
